
/* Application entry-point */
/* Import navbar component code */
import components from './components/index.js';

/* Import css */
import './css/stock-management.css';
import './css/Andika.css';
import './css/Grenache.css';

/* Plugin */
import Plugin from './lib/Plugin.js';

/* Themes */
import './themes/theme-scs-light.css';
import './themes/theme-scs-dark.css';

// TODO move to module
import { SchedulerPro, Toast, DomHelper, TabBar, DateHelper, StateProvider, Mask } from '/3rdparty/schedulerpro/schedulerpro.module.min.js';
window.SchedulerPro = SchedulerPro;
window.Toast = Toast;
window.DomHelper = DomHelper;
window.TabBar = TabBar;
window.DateHelper = DateHelper;
window.StateProvider = StateProvider;
window.Mask = Mask;

StateProvider.setup('local');

/* Define default routes */
const default_routes = {
	guest: '/grape-ui/log-in',
	all: '/stock/stock_levels'
};

window.Grape.component.registerComponents(components);

/* "main" */
document.addEventListener('DOMContentLoaded', () => {
	/* Setup app */
	Grape.config.default_routes = default_routes;

	Grape.plugins.register(Plugin);

	Grape.themes.registerThemes([
		{ name: 'SCS', className: 'scs' }
	]);
	Grape.themes.setThemeDefaults('scs', 'light');

	/* Start app */
	Grape.init();
});
