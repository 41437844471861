import template from './navbar.html';
import NavbarHelper from '@platsoft/ui-ps/src/navbar/NavbarHelper.js';

class NavbarViewModel extends NavbarHelper
{
	constructor (Grape)
	{
		super(Grape);
		this.load_logo();
	}

	async load_logo ()
	{
		let use_scs_logo = Grape.config.public_settings.use_scs_logo;
		let use_scs_logo_in_sidebar = Grape.config.public_settings.use_scs_logo_in_sidebar;

		let img = document.getElementById('company-logo-img');
		let sidebar_img = document.getElementById('sidebar-company-logo-img');
		
		if (use_scs_logo)
		{
			img.src = 'YoomaSCSLogo.svg';
			img.style.width = '10em';
			img.style.maxHeight = '';
			img.alt = 'Yooma SCS Logo';

			this.sidebar_img_visible(false);
		}
		else if (!use_scs_logo)
		{
			try
			{
				let result = await fetch(`/api/stock-management/company/logo/download/`, {
					method: 'GET',
					headers: { 'Content-Type': 'image/png' }
				});
	
				if (result.ok)
				{
					let blob = await result.blob();
					img.src = URL.createObjectURL(blob);
					img.style.maxHeight = '44px';
					img.style.width = '';
					img.alt = 'Company Logo';
				}
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error(error)
			}
		}

		if (use_scs_logo_in_sidebar && this.sidebar_img_visible())
		{
			sidebar_img.src = 'YoomaSCSLogo.svg';
			sidebar_img.style.width = '7em';
			sidebar_img.style.maxHeight = '';
			sidebar_img.alt = 'Yooma SCS Logo';
		}
	}

	init ()
	{
		super.init();
		this.title_visibility = ko.observable(true);
		this.current_page_route = ko.observable();
		this.active_nav = ko.observable(null);
		this.active_side_nav = ko.observable(null);
		this.active_side_nav_main = ko.observable(null);
		this.side_nav_visible = ko.observable(true);
		this.sidebar_img_visible = ko.observable(true);

		this.side_nav_items = ko.observableArray([			
			{
				page_route: '/stock/landing-page/',
				title: 'Home',
				icon: 'fad fa-house-chimney',
				sub_pages: [],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'stock.operator'
			},
			{
				page_route: '/dashboard',
				title: 'Dashboard',
				icon: 'fad fa-chart-mixed',
				sub_pages: [],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'stock.dashboard'
			},
			{
				page_route: '/stock_item/list',
				title: 'Stock Items',
				icon: 'fad fa-dolly-flatbed-alt',
				sub_pages: [],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'stock.operator'
			},
			{
				page_route: null,
				title: 'Stock',
				icon: 'fad fa-chart-column',
				sub_pages: [
					{ page_route: '/stock/stock_levels', title: 'Stock Levels', icon: '' },
					{ page_route: '/stock/stock_levels/summary', title: 'Stock Levels Summary', icon: '' },
					{ page_route: '/stock/stock_take/', title: 'Stock Take', icon: '' },
					{ page_route: '/stock/movement/list', title: 'Stock Movements', icon: '' },
					{ page_route: '/stock/movement/transactions', title: 'Movement Transactions', icon: '' },
					{ page_route: '/stock/movement_summary', title: 'Movement Summary', icon: '' }
				],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'stock.operator'
			},
			{
				page_route: null,
				title: 'Orders',
				icon: 'fad fa-truck',
				sub_pages: [
					{ page_route: '/stock/order/create', title: 'Create Order', icon: '' },
					{ page_route: '/stock/order/all_orders', title: 'All Orders', icon: '' },
					{ page_route: '/stock/order/ibt', title: 'Inter-Branch Transfers', icon: '' },
					{ page_route: '/stock/order/sales_orders', title: 'Sales Orders', icon: '' },
					{ page_route: '/stock/order/purchase_orders', title: 'Purchase Orders', icon: '' },
					{ page_route: '/stock/order/batched_sales_orders', title: 'Batched Sales Orders', icon: '' },
					{ page_route: '/stock/order/usage_orders', title: 'Usage Orders', icon: '' }
				],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'stock.operator'
			},
			{
				page_route: null,
				title: 'Sales',
				icon: 'fad fa-cart-shopping',
				sub_pages: [
					{ page_route: '/sales/batches', title: 'Sales Batches', icon: '' },
					{ page_route: '/sales/sales_orders', title: 'Sales Orders', icon: '' },
					{ page_route: '/sales/requisitions', title: 'Requisitions', icon: '' },
					{ page_route: '/sales/recipients', title: 'Recipients', icon: '' }
				],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'non-existent'
			},
			{
				page_route: '/admin/locations/list',
				title: 'Locations',
				icon: 'fad fa-map-location-dot',
				sub_pages: [],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'stock.operator'
			},
			{
				page_route: '/locations/suppliers-list',
				title: 'Suppliers',
				icon: 'fad fa-boxes-packing',
				sub_pages: [],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'stock.operator'
			},
			{
				page_route: null,
				title: 'Projections',
				icon: 'fad fa-chart-line-up',
				sub_pages: [
					{ page_route: '/projections/projected_orders', title: 'Projected Purchase Orders', icon: '' },
					{ page_route: '/projections/future_shortages', title: 'Future Shortages', icon: '' }
				],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'nobody'
			},
			{
				page_route: null,
				title: 'Production',
				icon: 'fad fa-conveyor-belt-arm',
				sub_pages: [
					{ page_route: '/production/setup', title: 'Production Setup', icon: '' },
					{ page_route: '/workstation/setup', title: 'Workstation Setup', icon: '' },
					{ page_route: '/stock_item/list/bom', title: 'Bill of Materials', icon: '' },
					{ page_route: '/production/orders_and_execution', title: 'Orders & Execution', icon: '' },
					{ page_route: '/production/schedule', title: 'Production Schedule', icon: '' },
					{ page_route: '/production/master_planning', title: 'Master Planning', icon: '' }
				],
				sub_pages_visible: ko.observable(false),
				data_visible_roles: 'manufacturing.operator'
			}
		]);

		window.Grape.update_visible_roles();

		let main_side_nav_open = JSON.parse(window.localStorage.getItem('scs_main_side_nav_open'));

		if (!main_side_nav_open)
		{
			this.side_nav_visible(!main_side_nav_open);
			this.btn_toggle_visibility();
		}
	}

	btn_toggle_visibility ()
	{
		let sidebar = document.getElementById('resizable-sidebar');
		let ul = document.getElementById('resizable-ul');

		if (this.side_nav_visible())
		{
			this.side_nav_items().forEach(item => {
				if (item === this.active_side_nav_main())
					item.sub_pages_visible(false);
			});

			sidebar.style.minWidth = '4em';
			ul.style.width = '4em';

			window.localStorage.setItem('scs_main_side_nav_open', false);
		}
		else
		{
			this.side_nav_items().forEach(item => {
				if (item === this.active_side_nav_main())
					item.sub_pages_visible(true);
			});

			sidebar.style.minWidth = '17em';
			ul.style.width = '17em';

			window.localStorage.setItem('scs_main_side_nav_open', true);
		}

		this.side_nav_visible(!this.side_nav_visible());
	}

	open_side_nav_main_item (side_nav_main_item)
	{
		let sidebar = document.getElementById('resizable-sidebar');
		
		if (sidebar.style.minWidth === '4em' && side_nav_main_item.page_route === null)
			this.btn_toggle_visibility();

		this.active_side_nav_main(side_nav_main_item);
		this.side_nav_items().forEach(item => {
			if (item === side_nav_main_item)
				item.sub_pages_visible(true);
			else
				item.sub_pages_visible(false);
		});

		if (side_nav_main_item.page_route != null)
		{
			this.navigate(side_nav_main_item.page_route);
			this.active_nav(null);
			this.active_side_nav(null);
		}
	}

	set_side_nav_page (side_nav_item)
	{
		this.active_side_nav(side_nav_item);

		if (side_nav_item.page_route != null)
		{
			this.navigate(side_nav_item.page_route);
			this.active_nav(null);
		}
	}

	set_nav_page (nav_item, page_route)
	{
		this.navigate(page_route);
		this.active_nav(nav_item);
		this.active_side_nav(null);

		this.side_nav_items().forEach(item => {
			if (item === this.active_side_nav_main())
				item.sub_pages_visible(false);
		});
		this.active_side_nav_main(null);
	}

	navigate (page_route)
	{
		this.current_page_route(page_route);
		Grape.navigate(`${page_route}`);
	}

	back_to_top ()
	{
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}
}

export default {
	name: 'ko-scs-navbar',
	viewModel: NavbarViewModel,
	module_type: 'ko',
	template: template
}